import { call, options, params } from "./helpers";

export const sendMailMaintenance = (payload) => {
  return call(
    `/api/mail/maintenance`,
    options(payload, "POST", { "x-api-key": process.env.REACT_APP_API_KEY })
  );
};

export const sendMailGeneral = (payload) => {
  return call(
    `/api/mail/general`,
    options(payload, "POST", { "x-api-key": process.env.REACT_APP_API_KEY })
  );
};

export const sendMailAutomation = (payload) => {
  return call(
    `/api/mail/automation`,
    options(payload, "POST", { "x-api-key": process.env.REACT_APP_API_KEY })
  );
};

export const sendMailEnvironment = (payload) => {
  return call(
    `/api/mail/environment`,
    options(payload, "POST", { "x-api-key": process.env.REACT_APP_API_KEY })
  );
};
