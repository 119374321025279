import { Header as DefaultHeader } from "./Header";
import { Footer as DefaultFooter } from "./Footer";

export const Content = ({ Header, Footer, children }) => {
  return (
    <>
      <div className="bg-white">
        {Header || <DefaultHeader />}

        <main className="relative isolate overflow-hidden bg-white mt-32">
          {children}
        </main>

        {Footer || <DefaultFooter />}
      </div>
    </>
  );
};
