import React, { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";

export default (asyncFunction, opts = {}) => {
  const {
    immediate = true,
    reset,
    defaultPayload,
    showNotification,
    skipSuccessNotification,
    skipErrorNotification,
    forceCallback,
  } = opts;

  const [status, setStatus] = useState("idle");
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (payload, callback, opts = {}) => {
      const silentExecute = opts?.silent;
      if (!silentExecute) {
        setStatus("pending");
        setValue(null);
      }
      setError(null);

      return asyncFunction(
        typeof payload !== "undefined"
          ? opts?.overrideDefault
            ? payload
            : defaultPayload
            ? { ...defaultPayload, ...payload }
            : payload
          : defaultPayload
      )
        .then((response) => {
          setValue(response);
          if (!silentExecute) {
            setStatus("success");
          }

          if (showNotification && !skipSuccessNotification) {
            toast.success(showNotification.success);
          }

          if (typeof reset === "function") {
            reset();
          }
          if (typeof callback === "function") {
            callback(response);
          }
        })
        .catch((error) => {
          const updatedError =
            error?.message?.split(" - ") || error?.split(" - ");

          const errorMessage = {
            message: updatedError[0],
            missing: updatedError[1],
          };
          setError(errorMessage);

          if (showNotification && !skipErrorNotification) {
            toast.error(
              showNotification?.error
                ? showNotification?.error === "raw"
                  ? updatedError[0]
                  : showNotification?.error
                : "Uh oh.  Something went wrong..."
            );
          }

          setStatus("error");

          if (forceCallback && typeof callback === "function") {
            callback(errorMessage);
          }
        });
    },
    [asyncFunction]
  );
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return {
    execute,
    status,
    value,
    error,
    resetError: () => {
      setError(null);
      setStatus("idle");
    },
    resetValue: () => {
      setError(null);
      setStatus("idle");
      setValue(null);
    },
  };
};
