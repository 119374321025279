import {
  ArrowPathIcon,
  CalendarIcon,
  TruckIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Content } from "../../template/Content";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

const categories = [
  {
    name: "New Arrivals",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
  },
  {
    name: "Productivity",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/home-page-01-category-02.jpg",
  },
  {
    name: "Workspace",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/home-page-01-category-04.jpg",
  },
  {
    name: "Accessories",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/home-page-01-category-05.jpg",
  },
  {
    name: "Sale",
    href: "#",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/home-page-01-category-03.jpg",
  },
];

const incentives = [
  {
    name: "Free shipping",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg",
    description:
      "It's not actually free we just price it into the products. Someone's paying for it, and it's not us.",
  },
  {
    name: "10-year warranty",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg",
    description:
      "If it breaks in the first 10 years we'll replace it. After that you're on your own though.",
  },
  {
    name: "Exchanges",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg",
    description:
      "If you don't like it, trade it to one of your friends for something of theirs. Don't send it here though.",
  },
];

const navigation = {
  categories: [
    {
      id: "women",
      name: "Equipment",
      sections: [
        [
          {
            id: "shoes",
            name: "By Type",
            span: "col-span-2",
            items: [
              { name: "Centrifuges", href: "#" },
              { name: "Chillers", href: "#" },
              { name: "Cryogenics", href: "#" },
              { name: "DNA Sequencers", href: "#" },
              { name: "Freezers / Refrigerators", href: "#" },
              //{ name: "Fume Hoods & Biosafety Cabinets", href: "#" },
              { name: "HPLC - Liquid Chromatography", href: "#" },
              //{ name: "Heat Blocks", href: "#" },
              //{ name: "Hot Plates / Stirrers", href: "#" },
              { name: "Imagers & Scanners", href: "#" },
              { name: "Incubators", href: "#" },
              { name: "Liquid Handlers", href: "#" },
              { name: "Microplate Readers", href: "#" },
              { name: "Microplate Washers", href: "#" },
              { name: "Microscopes", href: "#" },
              { name: "Ovens", href: "#" },
              { name: "PCR & Thermal Cyclers", href: "#" },
              { name: "Pipettes", href: "#" },
              { name: "Robotics", href: "#" },
            ],
          },
        ],
        [
          {
            id: "clothing",
            name: "By Manufacturer",
            span: "col-span-2",
            items: [
              { name: "10x Genomics", href: "#" },
              { name: "Agilent Technologies", href: "#" },
              { name: "Applied Biosystems", href: "#" },
              { name: "Azenta Life Sciences (Formerly Brooks)", href: "#" },
              { name: "Beckman Coulter", href: "#" },
              { name: "Bio-Rad", href: "#" },
              { name: "Eppendorf", href: "#" },
              { name: "Hamilton Company", href: "#" },
              { name: "HighRes Biosolutions", href: "#" },
              { name: "Invitrogen", href: "#" },
              { name: "Liconic", href: "#" },
              { name: "Mettler Toledo", href: "#" },
              { name: "PerkinElmer", href: "#" },
              { name: "Qiagen", href: "#" },
              { name: "Tecan", href: "#" },
              { name: "Thermo Fisher Scientific", href: "#" },
            ],
          },
        ],
      ],
    },
    {
      id: "men",
      name: "Consumables",
      featured: [
        {
          name: "Accessories",
          href: "#",
          imageSrc:
            "https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg",
          imageAlt:
            "Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters.",
        },
        {
          name: "New Arrivals",
          href: "#",
          imageSrc:
            "https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg",
          imageAlt:
            "Drawstring top with elastic loop closure and textured interior padding.",
        },
        {
          name: "Artwork Tees",
          href: "#",
          imageSrc:
            "https://tailwindui.com/img/ecommerce-images/category-page-02-image-card-06.jpg",
          imageAlt:
            "Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.",
        },
      ],
      sections: [
        [
          {
            id: "shoes",
            name: "By Type",
            items: [
              { name: "", href: "#" },
              { name: "Boots", href: "#" },
              { name: "Sandals", href: "#" },
              { name: "Socks", href: "#" },
            ],
          },
          {
            id: "collection",
            name: "Shop Collection",
            items: [
              { name: "Everything", href: "#" },
              { name: "Core", href: "#" },
              { name: "New Arrivals", href: "#" },
              { name: "Sale", href: "#" },
            ],
          },
        ],
        [
          {
            id: "clothing",
            name: "All Clothing",
            items: [
              { name: "Basic Tees", href: "#" },
              { name: "Artwork Tees", href: "#" },
              { name: "Pants", href: "#" },
              { name: "Hoodies", href: "#" },
              { name: "Swimsuits", href: "#" },
            ],
          },
          {
            id: "accessories",
            name: "All Accessories",
            items: [
              { name: "Watches", href: "#" },
              { name: "Wallets", href: "#" },
              { name: "Bags", href: "#" },
              { name: "Sunglasses", href: "#" },
              { name: "Hats", href: "#" },
              { name: "Belts", href: "#" },
            ],
          },
        ],
        [
          {
            id: "brands",
            name: "Brands",
            items: [
              { name: "Re-Arranged", href: "#" },
              { name: "Counterfeit", href: "#" },
              { name: "Full Nelson", href: "#" },
              { name: "My Way", href: "#" },
            ],
          },
        ],
      ],
    },
  ],
  pages: [{ name: "Automation", href: "#" }],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Single = () => {
  const [open, setOpen] = useState(false);

  return (
    <Content>
      <div className="mx-auto max-w-3xl lg:max-w-7xl mt-32">
        <div className="bg-white">
          {/* Mobile menu */}
          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                    <div className="flex px-4 pb-2 pt-5">
                      <button
                        type="button"
                        className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Links */}
                    <Tab.Group as="div" className="mt-2">
                      <div className="border-b border-gray-200">
                        <Tab.List className="-mb-px flex space-x-8 px-4">
                          {navigation.categories.map((category) => (
                            <Tab
                              key={category.name}
                              className={({ selected }) =>
                                classNames(
                                  selected
                                    ? "border-indigo-600 text-indigo-600"
                                    : "border-transparent text-gray-900",
                                  "flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium"
                                )
                              }
                            >
                              {category.name}
                            </Tab>
                          ))}
                        </Tab.List>
                      </div>
                      <Tab.Panels as={Fragment}>
                        {navigation.categories.map((category) => (
                          <Tab.Panel
                            key={category.name}
                            className="space-y-10 px-4 pb-8 pt-10"
                          >
                            {category.sections.map((column, columnIdx) => (
                              <div key={columnIdx} className="space-y-10">
                                {column.map((section) => (
                                  <div key={section.name}>
                                    <p
                                      id={`${category.id}-${section.id}-heading-mobile`}
                                      className="font-medium text-gray-900"
                                    >
                                      {section.name}
                                    </p>
                                    <ul
                                      role="list"
                                      aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                                      className="mt-6 flex flex-col space-y-6"
                                    >
                                      {section.items.map((item) => (
                                        <li
                                          key={item.name}
                                          className="flow-root"
                                        >
                                          <a
                                            href={item.href}
                                            className="-m-2 block p-2 text-gray-500"
                                          >
                                            {item.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </Tab.Panel>
                        ))}
                      </Tab.Panels>
                    </Tab.Group>

                    <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                      {navigation.pages.map((page) => (
                        <div key={page.name} className="flow-root">
                          <a
                            href={page.href}
                            className="-m-2 block p-2 font-medium text-gray-900"
                          >
                            {page.name}
                          </a>
                        </div>
                      ))}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <header className="relative bg-white">
            <nav
              aria-label="Top"
              className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
            >
              <div className="border-t border-gray-200">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex flex-1 items-center lg:hidden">
                    <button
                      type="button"
                      className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setOpen(true)}
                    >
                      <span className="sr-only">Open menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>

                    <a
                      href="#"
                      className="ml-2 p-2 text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">Search</span>
                      <MagnifyingGlassIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </a>
                  </div>

                  {/* Flyout menus */}
                  <Popover.Group className="hidden lg:block lg:flex-1 lg:self-stretch">
                    <div className="flex h-full space-x-8">
                      {navigation.categories.map((category) => (
                        <Popover key={category.name} className="flex">
                          {({ open }) => (
                            <>
                              <div className="relative flex">
                                <Popover.Button
                                  className={classNames(
                                    open
                                      ? "text-indigo-600"
                                      : "text-gray-700 hover:text-gray-800",
                                    "relative z-10 flex items-center justify-center text-sm font-medium transition-colors duration-200 ease-out  focus-visible:outline-none"
                                  )}
                                >
                                  {category.name}
                                  <span
                                    className={classNames(
                                      open ? "bg-indigo-600" : "",
                                      "absolute inset-x-0 bottom-0 h-0.5 transition-colors duration-200 ease-out sm:mt-5 sm:translate-y-px sm:transform"
                                    )}
                                    aria-hidden="true"
                                  />
                                </Popover.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Popover.Panel className="absolute inset-x-0 top-full z-20">
                                  {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                  <div
                                    className="absolute inset-0 top-1/2 bg-white shadow"
                                    aria-hidden="true"
                                  />

                                  <div className="relative bg-white">
                                    <div className="mx-auto max-w-7xl px-8">
                                      <div className="grid grid-cols-1 gap-x-8 gap-y-10 py-16">
                                        <div
                                          className={classNames(
                                            "grid gap-x-8 gap-y-10 text-sm text-gray-500",
                                            `grid-cols-${
                                              category.sections?.length + 1
                                            }`
                                          )}
                                        >
                                          {category.sections.map(
                                            (column, columnIdx) => {
                                              return (
                                                <div
                                                  key={columnIdx}
                                                  className={
                                                    "space-y-10 grid grid-cols-2"
                                                  }
                                                >
                                                  {column.map((section) => {
                                                    return (
                                                      <div
                                                        key={section.name}
                                                        className={"col-span-1"}
                                                      >
                                                        <p
                                                          id={`${category.id}-${section.id}-heading`}
                                                          className="font-medium text-gray-900"
                                                        >
                                                          {section.name}
                                                        </p>
                                                        <ul
                                                          role="list"
                                                          aria-labelledby={`${category.id}-${section.id}-heading`}
                                                          className="mt-4 space-y-4"
                                                        >
                                                          {section.items.map(
                                                            (item) => (
                                                              <li
                                                                key={item.name}
                                                                className="flex"
                                                              >
                                                                <a
                                                                  href={
                                                                    item.href
                                                                  }
                                                                  className="hover:text-gray-800"
                                                                >
                                                                  {item.name}
                                                                </a>
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                              );
                                            }
                                          )}

                                          <div className={"col-span-1"}>
                                            <p className="font-medium text-gray-900">
                                              Custom Solutions
                                            </p>
                                            <div className="mt-4">
                                              <div className="group relative text-base sm:text-sm">
                                                <a
                                                  href={"/"}
                                                  className="mt-6 block font-medium text-gray-900"
                                                >
                                                  <span
                                                    className="absolute inset-0 z-10"
                                                    aria-hidden="true"
                                                  />
                                                  Request an Item
                                                </a>
                                                <p
                                                  aria-hidden="true"
                                                  className="mt-1"
                                                >
                                                  Let us know what you need
                                                </p>
                                              </div>
                                              <div className="group relative text-base sm:text-sm mt-8">
                                                <a
                                                  href={"/"}
                                                  className="mt-6 block font-medium text-gray-900"
                                                >
                                                  <span
                                                    className="absolute inset-0 z-10"
                                                    aria-hidden="true"
                                                  />
                                                  Create a System Build List
                                                </a>
                                                <p
                                                  aria-hidden="true"
                                                  className="mt-1"
                                                >
                                                  Chat now with our consultants
                                                  to help you build the ideal
                                                  system
                                                </p>
                                              </div>
                                              <div className="group relative text-base sm:text-sm mt-8">
                                                <a
                                                  href={"/"}
                                                  className="mt-6 block font-medium text-gray-900"
                                                >
                                                  <span
                                                    className="absolute inset-0 z-10"
                                                    aria-hidden="true"
                                                  />
                                                  Sell your Equipment
                                                </a>
                                                <p
                                                  aria-hidden="true"
                                                  className="mt-1"
                                                >
                                                  We partner with local labs to
                                                  help
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      ))}

                      {navigation.pages.map((page) => (
                        <a
                          key={page.name}
                          href={page.href}
                          className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                        >
                          {page.name}
                        </a>
                      ))}
                    </div>
                  </Popover.Group>

                  <div className="flex flex-1 items-center justify-end">
                    {/* Search */}
                    <a
                      href="#"
                      className="ml-6 hidden p-2 text-gray-400 hover:text-gray-500 lg:block"
                    >
                      <span className="sr-only">Search</span>
                      <MagnifyingGlassIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </a>

                    {/* Cart */}
                    <div className="ml-4 flow-root lg:ml-6">
                      <a href="#" className="group -m-2 flex items-center p-2">
                        <ShoppingBagIcon
                          className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                          0
                        </span>
                        <span className="sr-only">items in cart, view bag</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>
        </div>
      </div>
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="pt-24 text-center lg:mt-20">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Marketplace
          </h1>
          <p className="mx-auto mt-4 max-w-3xl text-base text-gray-500">
            Thoughtfully designed objects for the workspace, home, and travel.
          </p>
        </div>
      </div>

      <div className="mx-auto max-w-7xl  px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:justify-between lg:px-8">
        <div className="py-16 sm:py-24 xl:max-w-7xl flex-1">
          <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900">
              Shop by Category
            </h2>
            <a
              href="#"
              className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
            >
              Browse all categories
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>

          <div className="mt-4 flow-root">
            <div className="-my-2">
              <div className="relative box-content h-80 overflow-x-auto py-2 xl:overflow-visible">
                <div className="absolute flex space-x-8 px-4 sm:px-6 lg:px-8 xl:relative xl:grid xl:grid-cols-5 xl:gap-x-8 xl:space-x-0 xl:px-0">
                  {categories.map((category) => (
                    <a
                      key={category.name}
                      href={category.href}
                      className="relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto"
                    >
                      <span aria-hidden="true" className="absolute inset-0">
                        <img
                          src={category.imageSrc}
                          alt=""
                          className="h-full w-full object-cover object-center"
                        />
                      </span>
                      <span
                        aria-hidden="true"
                        className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                      />
                      <span className="relative mt-auto text-center text-xl font-bold text-white">
                        {category.name}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 px-4 sm:hidden">
            <a
              href="#"
              className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500"
            >
              Browse all categories
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-4 mt-12 sm:px-6 lg:px-8">
          <div className="rounded-2xl bg-gray-50 px-6 py-6 sm:p-8">
            <div className="mx-auto max-w-xl lg:max-w-none">
              <div className="mx-auto mt-12 grid max-w-sm grid-cols-1 gap-x-8 gap-y-10 sm:max-w-none lg:grid-cols-3">
                {incentives.map((incentive) => (
                  <div
                    key={incentive.name}
                    className="text-center sm:flex sm:text-left lg:block lg:text-center"
                  >
                    <div className="sm:flex-shrink-0">
                      <div className="flow-root">
                        <img
                          className="mx-auto h-16 w-16"
                          src={incentive.imageSrc}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="mt-3 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                      <h3 className="text-sm font-medium text-gray-900">
                        {incentive.name}
                      </h3>
                      <p className="mt-2 text-sm text-gray-500">
                        {incentive.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};
