import { Content } from "../../template/Content";
import {
  Bars3CenterLeftIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ClockIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  LifebuoyIcon,
  PhoneIcon,
  ShieldExclamationIcon,
  TableCellsIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import LarianTechCloud from "../../../images/LarianTechCloud.png";
import LarianTechGate from "../../../images/LarianTechGate.png";
import LarianTechNode from "../../../images/LarianTechNode.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TagSnapshot from "../../../images/TagSnapshot.png";
import TabletMock from "../../../images/TabletMock.png";
import useAsync from "../../../hooks/useAsync";
import { sendMailAutomation, sendMailEnvironment } from "../../../api/mail";
import useRequired from "../../../hooks/useRequired";
import { Switch } from "@headlessui/react";
import ReCAPTCHA from "react-google-recaptcha";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const features = [
  {
    name: "Equipment List",
    description:
      "Our platform adopts a one-source-of-truth methodology to keep your whole team in the loop",
    icon: TableCellsIcon,
  },
  {
    name: "Incidents",
    description:
      "Our built-in ticketing system keeps track of any issues related to the lab",
    icon: ShieldExclamationIcon,
  },
  {
    name: "Service & Maintenance",
    description:
      "Set reminders for service schedules and keep the rest of your team informed on upcoming visits",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "Manuals & Documents",
    description:
      "Upload manuals, quotes, POs, and other valueable documents for easy future access",
    icon: DocumentTextIcon,
  },
  {
    name: "Vendor Information",
    description:
      "Our directory keeps track of all your vendors, connects them to equipment, and logs their work",
    icon: UsersIcon,
  },
  {
    name: "Analysis",
    description:
      "Use all the saved data points to create important data-driven decisions about your lab",
    icon: ChartBarIcon,
  },
];

export default () => {
  const { pathname, hash, key } = useLocation();
  const [form, setForm] = useState({});
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          const y =
            element.getBoundingClientRect().top + window.pageYOffset - 250;

          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  const {
    value: result,
    execute: onSendMail,
    status: isSendingMail,
    error,
  } = useAsync(sendMailEnvironment, {
    immediate: false,
    showNotification: { error: "raw" },
    skipSuccessNotification: true,
  });

  const hasAll = useRequired(form, [
    "privacy",
    "name",
    "email",
    "message",
    "recaptcha",
  ]);

  return (
    <Content>
      <div className="lg:pb-0">
        <div className="mx-auto max-w-7xl px-6 py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Environment Monitoring
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Get realtime monitoring and alerts for your lab equipment provided
              by an industry proven company, installed by our team of experts
            </p>
            <div className="mt-4 flex justify-center">
              <p className="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
                <span className="hidden md:inline text-xs flex items-center">
                  Provided by Larian Tech
                </span>
              </p>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#contact"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Schedule a Demo
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-900 py-10">
        <div className="relative overflow-hidden">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              src={LarianTechCloud}
              alt="App screenshot"
              className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10 "
              width={2432}
              height={500}
            />
          </div>
        </div>
      </div>

      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
        <div
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#23b7e5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>

      <div className="bg-white px-6 py-32 lg:px-8">
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">
                    Environment Monitoring
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Gateway
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {[
                      {
                        name: "LCD Screen",
                        description:
                          'Gateway devices come standard with a 10" display to view all node device data as well as monitor connection statuses.  This device is the main communication between your lab and cloud services',
                      },
                      {
                        name: "Multiple Connection Options",
                        description:
                          "Gateway devices come standard with WiFi, LAN, and 4G (for emergency backup) connectivity options",
                      },
                      {
                        name: "Easy to configure",
                        description:
                          "Features such as the Wi-Fi network search make setting up and running the gateway device easy",
                      },
                    ].map((feature) => (
                      <div key={feature.name} className="relative">
                        <dt className="inline font-semibold text-gray-900">
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img
                src={LarianTechGate}
                alt="Product screenshot"
                className="w-[48rem] max-w-none sm:w-[57rem] md:-ml-4 lg:-ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>

        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">
                    Environment Monitoring
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Node Device
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {[
                      {
                        name: "LCD Screen",
                        description:
                          "Node devices come standard with LCD screen to easy see the current environment data.  Node-Lite devices are now available",
                      },
                      {
                        name: "Long Range",
                        description:
                          "Nodes can be as far as 200m away from the gateway device",
                      },
                      {
                        name: "Battery Powered Option",
                        description:
                          "Node-Lite devices come with a built in battery for cases where power is not accessible",
                      },
                      {
                        name: "Easily Configurable",
                        description:
                          "LarianTech offers many different sensors for the node device includes temperature, humidity, CO2, door, water level, and LN2 status",
                      },
                    ].map((feature) => (
                      <div key={feature.name} className="relative">
                        <dt className="inline font-semibold text-gray-900">
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="flex items-start justify-end lg:order-first">
                <img
                  src={LarianTechNode}
                  alt="Product screenshot"
                  className="w-[48rem] max-w-none sm:w-[57rem]"
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          </div>
        </div>

        {result?.status === "success" &&
        isSendingMail === "success" &&
        !error ? (
          <div className="text-center mb-32">
            <h1 className="text-base font-medium text-indigo-600">
              Request sent!
            </h1>
            <p className="mt-2 text-base text-gray-500">
              A customer success team member will reach out to you shortly
            </p>
          </div>
        ) : (
          <>
            <div className="mx-auto max-w-2xl text-center">
              <h2
                id="contact"
                className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
              >
                Schedule a Demo
              </h2>
              <p className="mt-2 text-lg leading-8 text-gray-600">
                Our team is ready to show you all of the functions and features
                of this monitoring system
              </p>
            </div>

            <div className="mx-auto mt-16 max-w-xl sm:mt-20">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Name{" "}
                    <span className="text-xs text-gray-500 font-light">
                      (Required)
                    </span>
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setForm({ ...form, name: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="company"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Company
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      autoComplete="company"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setForm({ ...form, company: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Email{" "}
                    <span className="text-xs text-gray-500 font-light">
                      (Required)
                    </span>
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="email"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Phone Number
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="phone"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) =>
                        setForm({ ...form, phone: e.target.value })
                      }
                    />
                  </div>
                </div>

                <fieldset>
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold leading-6 text-gray-900 mb-4"
                  >
                    What are you interested in?
                  </label>{" "}
                  <div className="space-y-5 ml-4">
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="schedule"
                          aria-describedby="offers-description"
                          name="schedule"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={(e) => {
                            setForm({
                              ...form,
                              schedule: e.target.checked,
                            });
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="schedule"
                          className="text-xs text-gray-900"
                        >
                          Scheduling a demo
                        </label>
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="info"
                          aria-describedby="comments-description"
                          name="info"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={(e) => {
                            setForm({ ...form, info: e.target.checked });
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="info" className="text-gray-900 text-xs">
                          More information
                        </label>{" "}
                      </div>
                    </div>
                    <div className="relative flex items-start">
                      <div className="flex h-6 items-center">
                        <input
                          id="purchase"
                          aria-describedby="candidates-description"
                          name="purchase"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={(e) => {
                            setForm({
                              ...form,
                              purchase: e.target.checked,
                            });
                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label
                          htmlFor="purchase"
                          className="text-xs text-gray-900"
                        >
                          Purchasing additional devices
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="message"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Message
                  </label>
                  <div className="mt-2.5">
                    <textarea
                      name="message"
                      id="message"
                      rows={4}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      defaultValue={""}
                      onChange={(e) =>
                        setForm({ ...form, message: e.target.value })
                      }
                      placeholder="Required"
                    />
                  </div>
                </div>
                <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
                  <div className="flex h-6 items-center">
                    <Switch
                      checked={form?.privacy}
                      onChange={(privacy) => setForm({ ...form, privacy })}
                      className={classNames(
                        form?.privacy ? "bg-indigo-600" : "bg-gray-200",
                        "flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      )}
                    >
                      <span className="sr-only">Agree to policies</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          form?.privacy ? "translate-x-3.5" : "translate-x-0",
                          "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  </div>
                  <Switch.Label className="text-sm leading-6 text-gray-600">
                    By selecting this, you agree to our{" "}
                    <a href="#" className="font-semibold text-indigo-600">
                      privacy&nbsp;policy
                    </a>
                    .
                  </Switch.Label>
                </Switch.Group>
              </div>
              {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                <div className="mt-5">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value) => setForm({ ...form, recaptcha: value })}
                  />
                </div>
              )}
              <div className="mt-10">
                <button
                  className={classNames(
                    "block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm",
                    hasAll && isSendingMail !== "pending"
                      ? " bg-indigo-600  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      : "bg-gray-300 pointer-events-none"
                  )}
                  onClick={() =>
                    onSendMail({
                      ...(form?.name ? { name: form?.name } : {}),
                      ...(form?.company ? { company: form?.company } : {}),
                      ...(form?.email ? { email: form?.email } : {}),
                      ...(form?.phone ? { phone: form?.phone } : {}),

                      ...(form?.schedule ? { schedule: form?.schedule } : {}),
                      ...(form?.info ? { info: form?.info } : {}),
                      ...(form?.purchase ? { purchase: form?.purchase } : {}),

                      ...(form?.message ? { message: form?.message } : {}),
                      ...(form?.recaptcha
                        ? { recaptcha: form?.recaptcha }
                        : {}),
                    })
                  }
                >
                  {isSendingMail === "pending"
                    ? "Sending Request"
                    : "Send Request"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Content>
  );
};
