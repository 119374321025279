import { useState } from "react";
import { Content } from "../../template/Content";
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { sendMailGeneral } from "../../../api/mail";
import useAsync from "../../../hooks/useAsync";
import useRequired from "../../../hooks/useRequired";
import ReCAPTCHA from "react-google-recaptcha";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default () => {
  const [form, setForm] = useState({});

  const {
    value: result,
    execute: onSendMail,
    status: isSendingMail,
    error,
  } = useAsync(sendMailGeneral, {
    immediate: false,
    showNotification: { error: "raw" },
    skipSuccessNotification: true,
  });

  console.log("fomr: ", form);

  return (
    <Content>
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0 lg:left-1/2">
          <img
            className="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full"
            src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=2560&h=3413&&q=80"
            alt=""
          />
        </div>

        <div className="pb-24 pt-16 sm:pb-32 sm:pt-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-32">
          <div className="px-6 lg:px-8">
            {result?.status === "success" &&
            isSendingMail === "success" &&
            !error ? (
              <div className="text-center mb-32">
                <h1 className="text-base font-medium text-indigo-600">
                  Request sent!
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  A customer success team member will reach out to you shortly
                </p>
              </div>
            ) : (
              <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  How can we help you?
                </h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                  Our customer success team is ready to talk shop about your
                  next project!
                </p>
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div className="sm:col-span-2">
                    <div className="flex justify-between text-sm leading-6  mt-8">
                      <label
                        htmlFor="phone"
                        className="block font-semibold text-gray-900"
                      >
                        Name
                      </label>
                      <p id="phone-description" className="text-gray-400">
                        Required
                      </p>
                    </div>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) =>
                          setForm({ ...form, name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div className="flex justify-between text-sm leading-6">
                      <label
                        htmlFor="phone"
                        className="block font-semibold text-gray-900"
                      >
                        Email
                      </label>
                      <p id="phone-description" className="text-gray-400">
                        Required
                      </p>
                    </div>
                    <div className="mt-2.5">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) =>
                          setForm({ ...form, email: e.target.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <div className="flex justify-between text-sm leading-6">
                      <label
                        htmlFor="phone"
                        className="block font-semibold text-gray-900"
                      >
                        Company
                      </label>
                    </div>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) =>
                          setForm({ ...form, company: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <fieldset>
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold leading-6 text-gray-900 mb-4"
                    >
                      What are you interested in?
                    </label>{" "}
                    <div className="space-y-5 ml-4">
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="platform"
                            aria-describedby="comments-description"
                            name="platform"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) => {
                              setForm({ ...form, platform: e.target.checked });
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="platform"
                            className="text-gray-900 text-xs"
                          >
                            ONE Platform
                          </label>{" "}
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="maintenance"
                            aria-describedby="candidates-description"
                            name="maintenance"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) => {
                              setForm({
                                ...form,
                                maintenance: e.target.checked,
                              });
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="maintenance"
                            className="text-xs text-gray-900"
                          >
                            Maintenance or repair
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="automation"
                            aria-describedby="offers-description"
                            name="automation"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) => {
                              setForm({
                                ...form,
                                automation: e.target.checked,
                              });
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="automation"
                            className="text-xs text-gray-900"
                          >
                            Automation system builds
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="environment"
                            aria-describedby="offers-description"
                            name="environment"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) => {
                              setForm({
                                ...form,
                                environment: e.target.checked,
                              });
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="environment"
                            className="text-xs text-gray-900"
                          >
                            Environment monitoring
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="buy"
                            aria-describedby="offers-description"
                            name="buy"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) => {
                              setForm({ ...form, buy: e.target.checked });
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="buy"
                            className="text-xs text-gray-900"
                          >
                            Buying equipment
                          </label>
                        </div>
                      </div>
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="sell"
                            aria-describedby="offers-description"
                            name="sell"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={(e) => {
                              setForm({ ...form, sell: e.target.checked });
                            }}
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="sell"
                            className="text-xs text-gray-900"
                          >
                            Selling equipment
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className="sm:col-span-2">
                    <div className="flex justify-between text-sm leading-6">
                      <label
                        htmlFor="message"
                        className="block text-sm font-semibold leading-6 text-gray-900"
                      >
                        How can we help you?
                      </label>
                      <p id="message-description" className="text-gray-400">
                        Max 500 characters
                      </p>
                    </div>
                    <div className="mt-2.5">
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        aria-describedby="message-description"
                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={""}
                        onChange={(e) =>
                          setForm({ ...form, message: e.target.value })
                        }
                        placeholder="Required"
                      />
                    </div>
                  </div>
                  <div className="mt-5">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={(value) =>
                        setForm({ ...form, recaptcha: value })
                      }
                    />
                  </div>
                </div>
                <div className="mt-10 flex justify-end border-t border-gray-900/10 pt-8">
                  <button
                    className={classNames(
                      "block w-full rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm",
                      useRequired(form, [
                        "name",
                        "email",
                        "message",
                        "recaptcha",
                      ]) && isSendingMail !== "pending"
                        ? " bg-indigo-600  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        : "bg-gray-300 pointer-events-none"
                    )}
                    onClick={() =>
                      onSendMail({
                        ...(form?.name ? { name: form?.name } : {}),
                        ...(form?.company ? { company: form?.company } : {}),
                        ...(form?.email ? { email: form?.email } : {}),
                        ...(form?.message ? { message: form?.message } : {}),
                        ...(form?.recaptcha
                          ? { recaptcha: form?.recaptcha }
                          : {}),
                        ...(form?.platform ? { platform: form?.platform } : {}),
                        ...(form?.maintenance
                          ? { maintenance: form?.maintenance }
                          : {}),
                        ...(form?.automation
                          ? { automation: form?.automation }
                          : {}),
                        ...(form?.environment
                          ? { environment: form?.environment }
                          : {}),
                        ...(form?.buy ? { buy: form?.buy } : {}),
                        ...(form?.sell ? { sell: form?.sell } : {}),
                      })
                    }
                  >
                    {isSendingMail === "pending"
                      ? "Sending Message"
                      : "Send Message"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Content>
  );
};
