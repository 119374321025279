export default (body, required = [], customFunction) => {
  const hasAll = required.every((key) =>
    Object.keys(body)
      .filter((key) => body[key])
      .includes(key)
  );

  const custom = typeof customFunction === "function" ? customFunction() : true;

  return hasAll && custom;
};
