const JSON_CONTENT_TYPE = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const headers = (method, isFile, additionalHeaders) => {
  // Get user from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  // Use JSON type where necessary
  const isJsonType =
    (method === "POST" && !isFile) || method === "DELETE" || method === "PUT";

  console.log("additionalHeaders: ", additionalHeaders);

  return {
    ...(isJsonType ? JSON_CONTENT_TYPE : {}),
    ...(user?.jwtToken ? { Authorization: `Bearer ${user.jwtToken}` } : {}),
    ...(additionalHeaders ? additionalHeaders : {}),
  };
};

export const call = async (url, userOptions, callback, responseOptions) => {
  let opts = userOptions || options();

  return await fetch(`${process.env.REACT_APP_API_URL}${url}`, opts)
    .then((apiResponse) => apiResponse)
    .then((apiResponse) => {
      if (callback && typeof callback === "function") {
        callback();
      }

      return apiResponse;
    })
    .then((res) => response(res, { csvText: responseOptions?.csvResponse }));
};

export const options = (body, method = "GET", additionalHeaders) => {
  // Note: Always use `files` as field
  const hasFiles = !!body?.files;

  // Create initial options
  let options = {
    method,
    headers: headers(method, hasFiles, additionalHeaders),
  };

  console.log("options: ", options);

  if (hasFiles) {
    const { files, information, bucket } = body;

    // Initate form data object
    const formData = new FormData();

    // Process files
    files.map((file) => formData.append("file", file));

    // Process bucket location for file uploads
    formData.append("bucket", bucket);

    // Process any additional data
    for (const name in information) {
      let appendingData = information[name];
      const isArray =
        Array.isArray(appendingData) || typeof appendingData === "object";

      formData.append(
        name,
        isArray ? JSON.stringify(appendingData) : appendingData
      );
    }

    options.body = formData;
  } else if (body) {
    options.body = body ? JSON.stringify(body) : null;
  }

  return options;
};

const response = (apiResponse, { fullError = false, csvText } = {}) =>
  apiResponse.text().then((text) => {
    const data = text && csvText ? text : JSON.parse(text);

    // If something with API call went wrong
    if (!apiResponse.ok) {
      // Logout if 401 came back from API
      if (apiResponse.status === 401) {
        localStorage.removeItem("user");
        return;
      }

      // Create and return error
      // TODO: figure out what is `fullError`
      const err = data
        ? fullError
          ? data
          : data.message
        : apiResponse.statusText;

      return Promise.reject(err);
    }

    return data;
  });

export const params = (payload) =>
  payload ? `?${new URLSearchParams(payload).toString()}` : "";
