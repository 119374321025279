import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
  XMarkIcon,
  ChevronDownIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  SquaresPlusIcon,
  PlayCircleIcon,
  PhoneIcon,
  ChevronRightIcon,
  ServerIcon,
  CloudIcon,
  WrenchScrewdriverIcon,
  BuildingStorefrontIcon,
  UserGroupIcon,
  TableCellsIcon,
  ShieldExclamationIcon,
  DocumentTextIcon,
  UsersIcon,
  DocumentChartBarIcon,
  ChartBarIcon,
  Bars3CenterLeftIcon,
  CalendarDaysIcon,
  ClockIcon,
  LifebuoyIcon,
  LockOpenIcon,
  MegaphoneIcon,
  CubeTransparentIcon,
  ShieldCheckIcon,
  CircleStackIcon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import Logo from "../../../images/LogoMark.png";
import { Footer, Header } from "../../template";
import AppPreview from "../../../images/AppDemo2.png";
import AppPreview2 from "../../../images/AppPreview2.png";
import AppPreview5 from "../../../images/AppPreview5.png";
import Feature1 from "../../../images/Feature1.png";
import AppPreview4 from "../../../images/AppPreview4.png";
import TabletDemo from "../../../images/TabletMock.png";
import TagSnapshot from "../../../images/TagSnapshot.png";
import LarianAutomation from "../../../images/LARIAN Automation LOGO_logo.png";
import TempGraphic from "../../../images/TempGraphic.png";
import Typewriter from "typewriter-effect";

import SystemBuild from "../../../images/SystemBuild.png";
import AppDemo from "../../../images/AppDemo.png";
import LarianTechDemo from "../../../images/LarianTechDemo.png";
import LarianAutoBuild from "../../../images/LarianAutomationBuild.png";
import ServicePreview from "../../../images/ServicePreview.png";

const products = [
  {
    name: "Analytics",
    description: "Get a better understanding of your traffic",
    href: "#",
    icon: ChartPieIcon,
  },
  {
    name: "Engagement",
    description: "Speak directly to your customers",
    href: "#",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Security",
    description: "Your customers’ data will be safe and secure",
    href: "#",
    icon: FingerPrintIcon,
  },
  {
    name: "Integrations",
    description: "Connect with third-party tools",
    href: "#",
    icon: SquaresPlusIcon,
  },
  {
    name: "Automations",
    description: "Build strategic funnels that will convert",
    href: "#",
    icon: ArrowPathIcon,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

const features = [
  {
    name: "Equipment List",
    description:
      "Our platform adopts a one-source-of-truth methodology to keep your whole team in the loop",
    icon: TableCellsIcon,
  },
  {
    name: "Incidents",
    description:
      "Our built-in ticketing system keeps track of any issues related to the lab",
    icon: ShieldExclamationIcon,
  },
  {
    name: "Service & Maintenance",
    description:
      "Set reminders for service schedules and keep the rest of your team informed on upcoming visits",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "Manuals & Documents",
    description:
      "Upload manuals, quotes, POs, and other valueable documents for easy future access",
    icon: DocumentTextIcon,
  },
  {
    name: "Vendor Information",
    description:
      "Our directory keeps track of all your vendors, connects them to equipment, and logs their work",
    icon: UsersIcon,
  },
  {
    name: "Analysis",
    description:
      "Use all the saved data points to create important data-driven decisions about your lab",
    icon: ChartBarIcon,
  },
];
const tiers = [
  {
    name: "Freelancer",
    id: "tier-freelancer",
    href: "#",
    priceMonthly: "$24",
    description: "The essentials to provide your best work for clients.",
    features: [
      "5 products",
      "Up to 1,000 subscribers",
      "Basic analytics",
      "48-hour support response time",
    ],
    mostPopular: false,
  },
  {
    name: "Startup",
    id: "tier-startup",
    href: "#",
    priceMonthly: "$32",
    description: "A plan that scales with your rapidly growing business.",
    features: [
      "25 products",
      "Up to 10,000 subscribers",
      "Advanced analytics",
      "24-hour support response time",
      "Marketing automations",
    ],
    mostPopular: true,
  },
  {
    name: "Enterprise",
    id: "tier-enterprise",
    href: "#",
    priceMonthly: "$48",
    description: "Dedicated support and infrastructure for your company.",
    features: [
      "Unlimited products",
      "Unlimited subscribers",
      "Advanced analytics",
      "1-hour, dedicated support response time",
      "Marketing automations",
    ],
    mostPopular: false,
  },
];
const faqs = [
  {
    id: 1,
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
];
const footerNavigation = {
  solutions: [
    { name: "Hosting", href: "#" },
    { name: "Data Services", href: "#" },
    { name: "Uptime Monitoring", href: "#" },
    { name: "Enterprise Services", href: "#" },
  ],
  support: [
    { name: "Pricing", href: "#" },
    { name: "Documentation", href: "#" },
    { name: "Guides", href: "#" },
    { name: "API Reference", href: "#" },
  ],
  company: [
    { name: "About", href: "#" },
    { name: "Blog", href: "#" },
    { name: "Jobs", href: "#" },
    { name: "Press", href: "#" },
    { name: "Partners", href: "#" },
  ],
  legal: [
    { name: "Claim", href: "#" },
    { name: "Privacy", href: "#" },
    { name: "Terms", href: "#" },
  ],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Home = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  console.log("localhost: ", localStorage);
  const user = localStorage.getItem("user");
  console.log("user: ", user);

  return (
    <div className="bg-white">
      <Header />

      <main className="relative isolate overflow-hidden bg-white">
        {/* Hero section */}

        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl py-48 sm:py-48 lg:py-56">
            <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <div className="inline-flex space-x-6">
                <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                  What's new
                </span>
                <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
                  <span>Platform shipped v0.9.1 🚀</span>
                  {/*<ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
  />*/}
                </span>
              </div>
            </div>
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Lab solutions to
                <span className="text-indigo-600">
                  {" "}
                  <Typewriter
                    options={{
                      strings: [
                        "Operate.",
                        "Monitor.",
                        "Automate.",
                        "Maintain.",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </span>
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                As a company, our goal is to help labs optimize their processes
                <br />
                and improve their data accuracy
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="/contact-us"
                  className="rounded-xl bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Chat with our team
                </a>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>

        {/*<div className="bg-white">
          <div className="mx-auto max-w-7xl py-10 sm:px-6 sm:pb-24 lg:px-8">
            <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16">
              <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
                🚀 NEW: Introducing the marketplace
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                Introducing the first lab-to-lab marketplace for used equipment.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="/marketplace"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Visit Marketplace
                </a>
                <a
                  href="/marketplace/sell"
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Sell your equipment <span aria-hidden="true">→</span>
                </a>
              </div>
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                aria-hidden="true"
              >
                <circle
                  cx={512}
                  cy={512}
                  r={512}
                  fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                  fillOpacity="0.7"
                />
                <defs>
                  <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                    <stop stopColor="#7775D6" />
                    <stop offset={1} stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
            </div>
          </div>
  </div>*/}

        {/* Feature section */}
        <div className="mx-auto mt-20 max-w-7xl px-6 sm:mt-10 lg:px-8 pb-10 sm:pb-32">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
              Overview
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our Solutions
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-7xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-4 lg:gap-y-16">
              {[
                {
                  name: (
                    <>
                      <span className="font-bold">Lab Ops</span> Platform
                    </>
                  ),
                  description:
                    "Our cloud-based platform keeps track of all data related to your equipment and consumables within your lab",
                  icon: CloudIcon,
                  href: "/solutions/platform",
                  label: "Learn more",
                },
                /*{
                  name: "Marketplace",
                  description:
                    "Recently introduced, we've released the worlds first lab-to-lab marketplace for equipment and consumables",
                  icon: BuildingStorefrontIcon,
                  href: "#",
                  label: "Visit our storefront",
                },*/

                {
                  name: "Automation Systems",
                  description:
                    "Help us automate your lab with fully custom system designed to fit your needs",
                  //"We partner with Larian Automation to bring you fully built automation systems which are custom designed to fit your need",
                  icon: CubeTransparentIcon,
                  href: "/solutions/automation",
                  label: "Learn more",
                  reseller: true,
                },
                {
                  name: "Environment Monitoring",
                  description:
                    "We are proud to exclusively sell and install LarianTech top-of-the-line environment monitoring products",
                  icon: ShieldCheckIcon,
                  href: "/solutions/monitoring",
                  label: "Learn more",
                  reseller: true,
                },
                {
                  name: "Equipment Service",
                  description:
                    "Our field service engineers work around the clock to provide you with full support for all your lab equipment",
                  icon: WrenchScrewdriverIcon,
                  href: "/solutions/service",
                  label: "Request service",
                },
              ].map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg ">
                      <feature.icon
                        className="h-8 w-8 text-indigo-800"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600 text-sm">
                    {feature.description}

                    <div className="mt-4">
                      <a
                        href={feature.href}
                        className="text-sm font-semibold leading-6 text-indigo-600"
                      >
                        {feature.label} <span aria-hidden="true">→</span>
                      </a>
                    </div>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        {/*}
        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-6 lg:px-8  pt-16">
            <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Lab Ops Platform
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                ONE Platform
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We strive to help labs automate their day-to-day operational
                processes so they can focus on the tasks that really matter
                while storing all their data centrally
              </p>
            </div>
          </div>

          <div className="relative overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <img
                src={Feature1}
                alt="App screenshot"
                className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
                width={2432}
                height={1442}
              />
              <div className="relative" aria-hidden="true">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
              </div>
            </div>
          </div>

          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>

            <div className="pt-20 text-center">
              <a
                href="/platform"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white sm:pb-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:ml-auto lg:pl-4 lg:pt-4 lg:flex lg:items-center">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">
                    QR Asset Tags
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Equipment Snapshot
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Simply scan the QR asset tag located on each unit to get the
                    most up-to-date information
                  </p>
                 

                  <div className="mt-10 flex items-center gap-x-6">
                    <a
                      href="/platform#qr-asset-tags"
                      className="rounded-md bg-indigo-600 px-6 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex items-start justify-end lg:order-first">
                <img
                  src={TagSnapshot}
                  alt="Product screenshot"
                  className="w-[48rem] max-w-none  sm:w-[57rem]"
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-gray-900 py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4 lg:flex lg:items-center">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-400 flex items-center">
                    Lab Status Tablet{" "}
                    <span className="ml-3 inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
                      BETA
                    </span>
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    Communicate Visually
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-300">
                    Keep your team up-to-date on what is happening in the lab
                    without having them check emails or the platform. <br />
                    <br />
                    Simply set up this platform-connected tablet near the lab
                    entrance or system
                  </p>
                  <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                    <a
                      href="/platform#lab-status"
                      className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                    >
                      Learn more
                    </a>
                  </div>
                 
                </div>
              </div>
              <img
                src={TabletDemo}
                alt="Product screenshot"
                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>

        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Why use our platform?
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                We're with you every step of the way
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Our team is always available to make sure that you succeed in
                running your lab
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
                {[
                  {
                    name: "One Source of Truth",
                    description:
                      "In a world that uses data to make decisions, this industry has definitely been behind the curve.  We're here to change that.  No matter how large your lab and team grow, your data is stored safely for you to access",
                    href: "#",
                    icon: TableCellsIcon,
                  },
                  {
                    name: "Communication",
                    description:
                      "Keep your whole team on the same page without them needing access to one more platform.  No more missed services due to miscommunication",
                    href: "#",
                    icon: MegaphoneIcon,
                  },
                  {
                    name: "Data Loss Prevention",
                    description:
                      "Our scientist friendly approach allows us to capture critical data points surrounding incidents and downtime as soon as they happen",
                    href: "#",
                    icon: CircleStackIcon,
                  },
                  {
                    name: "Scalability",
                    description:
                      "We're here to work with you if you are a one person lab ops team or are a lab director with a team of ten!  Our platform is scalable across multiple teams and locations",
                    href: "#",
                    icon: ArrowTrendingUpIcon,
                  },
                ].map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                      <feature.icon
                        className="h-5 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>
                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <p className="flex-auto">{feature.description}</p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
                </div>*/}

        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4 flex items-center">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Lab Ops Platform
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    We're centralizing all that is needed for lab ops to run
                    smoothly and efficiently by automating tasks that take up
                    your valueable time.
                  </p>
                  <div className="mt-8">
                    <a
                      href="/solutions/platform"
                      className="inline-flex rounded-xl bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Learn More
                    </a>

                    <a
                      href="#"
                      className="text-sm font-semibold leading-6 text-gray-900 ml-8"
                    >
                      Get Started <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </div>
              <img
                src={AppDemo}
                alt="Product screenshot"
                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:ml-auto lg:pl-4 lg:pt-4 flex items-center">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Automation Systems
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Spend more time doing research and less time pipetting by
                    building an automation system that can run around the clock
                    on its own time!
                  </p>
                  <div className="mt-8">
                    <a
                      href="/solutions/automation"
                      className="inline-flex rounded-xl bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Learn More
                    </a>
                    <a
                      href="/contact-us"
                      className="text-sm font-semibold leading-6 text-gray-900 ml-8"
                    >
                      Get Started <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex items-start justify-end lg:order-first">
                <img
                  src={LarianAutoBuild}
                  alt="Product screenshot"
                  className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4 flex items-center">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Environment Monitoring
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Get realtime monitoring and alerts for your lab equipment
                    provided by an industry proven company, installed by our
                    team of experts
                  </p>
                  <div className="mt-8">
                    <a
                      href="/solutions/monitoring"
                      className="inline-flex rounded-xl bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Learn More
                    </a>
                    <a
                      href="/contact-us"
                      className="text-sm font-semibold leading-6 text-gray-900 ml-8"
                    >
                      Get Started <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </div>
              <img
                src={LarianTechDemo}
                alt="Product screenshot"
                className="w-[48rem] max-w-none sm:w-[57rem] md:-ml-4 lg:-ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:ml-auto lg:pl-4 lg:pt-4 flex items-center">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Maintenance & Repair
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    With over 20+ years of experience, we specialize in all
                    types of equipment including incubators, sealers, peelers,
                    liquid dispensers, etc.
                  </p>
                  <div className="mt-8">
                    <a
                      href="/solutions/service"
                      className="inline-flex rounded-xl bg-indigo-600 px-5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Learn More
                    </a>
                    <a
                      href="/contact-us"
                      className="text-sm font-semibold leading-6 text-gray-900 ml-8"
                    >
                      Get Started <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex items-start justify-end lg:order-first">
                <img
                  src={ServicePreview}
                  alt="Product screenshot"
                  className="w-[48rem] max-w-none sm:w-[57rem]"
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-indigo-700">
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Ready to get started?
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-200">
                Our customer success team is ready to help you run operations
                smoothly and efficiently
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="/contact-us"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Chat with our team!
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*}
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:text-center">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                Solutions
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Additional Services
              </p>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {[
                  {
                    name: "Maintenance & Repair",
                    description:
                      "Our field service engineers work around the clock to provide you with full support for all your lab equipment                    ",
                    href: "#",
                    icon: WrenchScrewdriverIcon,
                  },
                  {
                    name: "Automation Systems",
                    description:
                      "We partner with Larian Automation to bring you fully built automation systems which are custom designed to fit your need",
                    href: "#",
                    icon: CubeTransparentIcon,
                  },
                  {
                    name: "Environment Monitoring",
                    description:
                      "Our partners over at LarianTech have produced top-of-the-line environment monitoring hardware which we are proud to sell and install",
                    href: "#",
                    icon: ShieldCheckIcon,
                  },
                ].map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                      <feature.icon
                        className="h-5 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>
                    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <p className="flex-auto">{feature.description}</p>
                      <p className="mt-6">
                        <a
                          href={feature.href}
                          className="text-sm font-semibold leading-6 text-indigo-600"
                        >
                          Learn more <span aria-hidden="true">→</span>
                        </a>
                      </p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">
                    Deploy faster
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    A better workflow
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img
                src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                alt="Product screenshot"
                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">
                    Automation Systems
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    A better workflow
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="flex items-start justify-end lg:order-first">
                <img
                  src="https://uploads-ssl.webflow.com/63c77563fa412616adf6c407/63ef274ca90a075c0ef1911b_PhotoRoom_20230217_80416%20AM.png"
                  alt="Product screenshot"
                  className="w-[24rem] max-w-none sm:w-[54rem]"
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <h2 className="text-base font-semibold leading-7 text-indigo-600">
                    Deploy faster
                  </h2>
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    A better workflow
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Maiores impedit perferendis suscipit eaque, iste dolor
                    cupiditate blanditiis ratione.
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img
                alt="Product screenshot"
                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                width={2432}
                height={1442}
                src="https://www.lariantech.com/assets/images/gate.png"
              />
            </div>
          </div>
                    </div>*/}
      </main>

      <Footer />
    </div>
  );
};
