import Logo from "../../images/LogoMark.png";

const navigation = {
  solutions: [
    { name: "Lab Ops Platform", href: "/solutions/platform" },
    { name: "Automation Systems", href: "/solutions/automation" },
    { name: "Environment Monitoring", href: "/solutions/monitoring" },
    { name: "Maintenance & Repair", href: "/solutions/service" },
  ],
  resources: [{ name: "Support Desk", href: "/contact-us" }],
  resources: [{ name: "Contact Us", href: "/contact-us" }],
};

export const Footer = () => {
  return (
    <>
      {/* Footer */}
      <footer className="bg-gray-900" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <div className="font-semibold text-xl text-white flex items-center">
                <img className="h-7 mr-3" src={Logo} alt="Company name" />
                Scipods
              </div>
              <p className="text-sm leading-6 text-gray-300">
                100 Cummings Center
                <br /> Suite 222G
                <br />
                Beverly, MA 01915
              </p>
              <div>
                <a
                  href={"#"}
                  className="text-sm leading-6 text-gray-300 hover:text-white mr-3"
                >
                  Contact
                </a>
                <a
                  href={"#"}
                  className="text-sm leading-6 text-gray-300 hover:text-white mr-3"
                >
                  Privacy
                </a>
                <a
                  href={"#"}
                  className="text-sm leading-6 text-gray-300 hover:text-white"
                >
                  Terms
                </a>
              </div>
            </div>

            <div className="mt-16 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-3 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Solutions
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                {/*}
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Marketplace
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                    </div>*/}

                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-white">
                    Resources
                  </h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.resources.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-sm leading-6 text-gray-300 hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
