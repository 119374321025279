import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  CloudIcon,
  CubeTransparentIcon,
  ShieldCheckIcon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import Logo from "../../images/LogoMark.png";

const products = [
  {
    name: "Lab Ops Platform",
    description:
      "A cloud-based lab operations platform that manage day-to-day tasks",
    href: "/solutions/platform",
    icon: CloudIcon,
    more: "Learn more",
  },
  /*{
    name: "Automation Systems",
    description:
      "Integrated workcells built to spec which automate scientific processes",
    href: "/solutions/automation",
    icon: CubeTransparentIcon,
    more: "Learn more",
  },*/
  {
    name: "Environment Monitoring",
    description:
      "A platform for monitoring temperature, humidity, CO2, O2, and many other environments",
    href: "/solutions/monitoring",
    icon: ShieldCheckIcon,
    more: "Learn more",
  },
  {
    name: "Maintenance & Repair",
    description:
      "Service offered by our team to repair and maintain laboratory equipment",
    href: "/solutions/service",
    icon: WrenchScrewdriverIcon,
    more: "Request service",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <header className="bg-white absolute inset-x-0 top-0 z-40">
        <a
          className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
          href="/events/lab-facilities-west-2024"
        >
          <p className="text-sm leading-6 text-white">
            <div>
              <strong className="font-semibold">
                Next Event: LRIG New England
              </strong>
              <svg
                viewBox="0 0 2 2"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
                aria-hidden="true"
              >
                <circle cx={1} cy={1} r={1} />
              </svg>
              Join us on October 10th at the Boston Mariott Cambridge in Boston&nbsp;
              {/*<span aria-hidden="true">&rarr;</span>*/}
            </div>
          </p>
          <div className="flex flex-1 justify-end"></div>{" "}
        </a>

        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8 z-49"
          aria-label="Global"
        >
          <div className="flex lg:flex-1 ">
            <a href="/" className="-m-1.5 p-1.5 flex items-center">
              <span className="sr-only">Scipods</span>
              <img className="h-8 w-auto" src={Logo} alt="" />
              <span className="font-semibold pl-3 text-xl text-gray-900">
                Scipods
              </span>
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Popover.Group className="hidden lg:flex lg:gap-x-12">
              <Popover>
                <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus-visible:outline-none mr-10">
                  Solutions
                  <ChevronDownIcon
                    className="h-3 w-3 flex-none text-gray-600 ml-1.5"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 -translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 -translate-y-1"
                >
                  <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white pt-28 shadow-lg ring-1 ring-gray-900/5">
                    <div className="mx-auto grid max-w-7xl grid-cols-3 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                      {products.map((item) => (
                        <div
                          key={item.name}
                          className="group relative rounded-lg p-6 text-sm leading-6 hover:bg-gray-50"
                        >
                          <div className="flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon
                              className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                          </div>
                          <a
                            href={item.href}
                            className="mt-6 block font-semibold text-gray-900"
                          >
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-1 text-gray-600">
                            {item.description}
                          </p>

                          <div class="mt-4">
                            <a
                              href="/platform"
                              class="text-sm font-semibold leading-6 text-indigo-600"
                            >
                              {item.more} <span aria-hidden="true">→</span>
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>

              {/*} <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Resources
            </a>
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Company
                    </a>*/}
            </Popover.Group>
            <a
              href="https://cloud.scipods.com"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Log in
            </a>
            <a
              href="/contact-us"
              className="text-sm font-semibold leading-6 text-gray-900 ml-10"
            >
              Chat with our team <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />

          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
              <p className="text-sm leading-6 text-white">
                <div href="#">
                  <strong className="font-semibold">
                    Next Event: LRIG New England
                  </strong>
                  <svg
                    viewBox="0 0 2 2"
                    className="mx-2 inline h-0.5 w-0.5 fill-current"
                    aria-hidden="true"
                  >
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  Join us on October 10th at the Boston Mariott Cambridge in Boston
                  &nbsp;
                  {/*<span aria-hidden="true">&rarr;</span>*/}
                </div>
              </p>
              <div className="flex flex-1 justify-end"></div>{" "}
            </div>
            <div className="px-6 py-6">
              <div className="flex items-center justify-between">
                <a href="/" className="-m-1.5 p-1.5 flex items-center">
                  <span className="sr-only">Scipods</span>
                  <img className="h-8 w-auto" src={Logo} alt="" />
                  <span className="font-semibold pl-3 text-xl text-gray-900">
                    Scipods
                  </span>
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                            Solutions
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-3 w-3 flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="mt-2 space-y-2">
                            {[...products].map((item) => (
                              <Disclosure.Button
                                key={item.name}
                                as="a"
                                href={item.href}
                                className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                              >
                                {item.name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                          <Disclosure.Button
                            as="a"
                            href={"/contact-us"}
                            className="mt-4 pt-4 border-t flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Contact Us
                          </Disclosure.Button>
                          <Disclosure.Button
                            as="a"
                            href={"https://cloud.scipods.com"}
                            className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            Login
                          </Disclosure.Button>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </>
  );
};

/*

 <Popover.Group className="hidden lg:flex lg:gap-x-12">
            <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus-visible:outline-none">
                Solutions
                <ChevronDownIcon
                  className="h-3 w-3 flex-none text-gray-600"
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-xl bg-white shadow-lg ring-1 ring-gray-900/5">
                  <div className="p-2">
                    {[
                      {
                        name: "Overview",
                        description: "See what all the hype is about",
                        icon: Squares2X2Icon,
                        href: "/platform",
                      },
                      {
                        name: "Features",
                        description: "Making your life easier",
                        icon: QueueListIcon,
                        href: "/platform#features",
                      },
                      {
                        name: "QR Asset Tags",
                        description: "Equipment Snapshot",
                        icon: QrCodeIcon,
                        href: "/platform#qr-asset-tags",
                      },

                      {
                        name: "Lab Status",
                        description: "Communication via tablet",
                        beta: true,
                        icon: DeviceTabletIcon,
                        href: "/platform#lab-status",
                      },
                      /*{
                        name: "Changelog",
                        description:
                          "Keep up with all of the features we're releasing",
                        icon: RocketLaunchIcon,
                        href: "/platform/changelog",
                      },
                    ].map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-5 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-8 w-8 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex-auto">
                          <a
                            href={item.href}
                            className="block font-semibold text-gray-900 cursor-pointer flex items-center"
                          >
                            {item.name}
                            {item.beta ? (
                              <span className="ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                <small>BETA</small>
                              </span>
                            ) : null}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-0 text-gray-600 text-xs">
                            {item.description}
                          </p>
                        </div>
                        <div>
                          <ChevronRightIcon className="text-gray-400 w-4 h-4" />
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            {/*<a
              href="/marketplace"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Marketplace
                            </a>
            {/*}
            <a
              href="https://cloud.scipods.com"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Repair Services
            </a>

            <a
              href="https://cloud.scipods.com"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Automation
            </a>

            <a
              href="https://cloud.scipods.com"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Environment
            </a>
            
            <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-3 text-sm font-semibold leading-6 text-gray-900 focus-visible:outline-none">
                Solutions
                <ChevronDownIcon
                  className="h-3 w-3 flex-none text-gray-600"
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                  <div className="p-4">
                    {[
                      {
                        name: "Maintenance & Repair",
                        description: "Supporting your equipment",
                        icon: WrenchScrewdriverIcon,
                        href: "/solutions/service",
                      },
                      {
                        name: "Automation Systems",
                        description: "Custom built automation systems",
                        icon: CubeTransparentIcon,
                        href: "/solutions/automation",
                      },
                      {
                        name: "Environment Monitoring",
                        description: "Independent monitoring and alerting",
                        icon: ShieldCheckIcon,
                        href: "/solutions/monitoring",
                      },
                    ].map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-5 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-8 w-8 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex-auto">
                          <a
                            href={item.href}
                            className="block font-semibold text-gray-900 cursor-pointer flex items-center"
                          >
                            {item.name}
                            {item.beta ? (
                              <span className="ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                <small>BETA</small>
                              </span>
                            ) : null}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-0 text-gray-600 text-xs">
                            {item.description}
                          </p>
                        </div>
                        <div>
                          <ChevronRightIcon className="text-gray-400 w-4 h-4" />
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
                            </Popover>

            <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-3 text-sm font-semibold leading-6 text-gray-900 focus-visible:outline-none">
                Services
                <ChevronDownIcon
                  className="h-3 w-3 flex-none text-gray-600"
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                  <div className="p-4">
                    {[
                      {
                        name: "Maintenance & Repair",
                        description: "Supporting your equipment",
                        icon: WrenchScrewdriverIcon,
                        href: "/solutions/service",
                      },
                    ].map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-5 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-8 w-8 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex-auto">
                          <a
                            href={item.href}
                            className="block font-semibold text-gray-900 cursor-pointer flex items-center"
                          >
                            {item.name}
                            {item.beta ? (
                              <span className="ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                <small>BETA</small>
                              </span>
                            ) : null}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-0 text-gray-600 text-xs">
                            {item.description}
                          </p>
                        </div>
                        <div>
                          <ChevronRightIcon className="text-gray-400 w-4 h-4" />
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-3 text-sm font-semibold leading-6 text-gray-900 focus-visible:outline-none">
                Products
                <ChevronDownIcon
                  className="h-3 w-3 flex-none text-gray-600"
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                  <div className="p-4">
                    {[
                      {
                        name: "Automation Systems",
                        description: "Custom built automation systems",
                        icon: CubeTransparentIcon,
                        href: "/solutions/automation",
                      },
                      {
                        name: "Environment Monitoring",
                        description: "Independent monitoring and alerting",
                        icon: ShieldCheckIcon,
                        href: "/solutions/monitoring",
                      },
                    ].map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-5 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-8 w-8 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex-auto">
                          <a
                            href={item.href}
                            className="block font-semibold text-gray-900 cursor-pointer flex items-center"
                          >
                            {item.name}
                            {item.beta ? (
                              <span className="ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                <small>BETA</small>
                              </span>
                            ) : null}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-0 text-gray-600 text-xs">
                            {item.description}
                          </p>
                        </div>
                        <div>
                          <ChevronRightIcon className="text-gray-400 w-4 h-4" />
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-3 text-sm font-semibold leading-6 text-gray-900 focus-visible:outline-none">
                About
                <ChevronDownIcon
                  className="h-3 w-3 flex-none text-gray-600"
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                  <div className="p-4">
                    {[
                      {
                        name: "Automation Systems",
                        description: "Custom built automation systems",
                        icon: CubeTransparentIcon,
                        href: "/solutions/automation",
                      },
                      {
                        name: "Environment Monitoring",
                        description: "Independent monitoring and alerting",
                        icon: ShieldCheckIcon,
                        href: "/solutions/monitoring",
                      },
                    ].map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-5 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-8 w-8 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex-auto">
                          <a
                            href={item.href}
                            className="block font-semibold text-gray-900 cursor-pointer flex items-center"
                          >
                            {item.name}
                            {item.beta ? (
                              <span className="ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                <small>BETA</small>
                              </span>
                            ) : null}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-0 text-gray-600 text-xs">
                            {item.description}
                          </p>
                        </div>
                        <div>
                          <ChevronRightIcon className="text-gray-400 w-4 h-4" />
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            {/*} <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus-visible:outline-none">
                Resources
                <ChevronDownIcon
                  className="h-3 w-3 flex-none text-gray-600"
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ">
                  <div className="p-4">
                    {[
                      {
                        name: "Company",
                        description: "Find out what all the hype is about!",
                        icon: BuildingOfficeIcon,
                        href: "/support",
                      },
                      {
                        name: "Blog",
                        description: "Find out what all the hype is about!",
                        icon: MegaphoneIcon,
                        href: "/support",
                      },
                      {
                        name: "Support Desk",
                        description: "Find out what all the hype is about!",
                        icon: LifebuoyIcon,
                        href: "/support",
                      },
                    ].map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-5 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-8 w-8 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon
                            className="h-5 w-5 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex-auto">
                          <a
                            href={item.href}
                            className="block font-semibold text-gray-900 cursor-pointer flex items-center"
                          >
                            {item.name}
                            {item.beta ? (
                              <span className="ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
                                <small>BETA</small>
                              </span>
                            ) : null}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-0 text-gray-600 text-xs">
                            {item.description}
                          </p>
                        </div>
                        <div>
                          <ChevronRightIcon className="text-gray-400 w-4 h-4" />
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
                            </Popover>
         // </Popover.Group>

*/
