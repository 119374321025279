import { Content } from "../../template/Content";
import {
  Bars3CenterLeftIcon,
  BuildingOffice2Icon,
  CalendarDaysIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ClockIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  LifebuoyIcon,
  PhoneIcon,
  ShieldExclamationIcon,
  TableCellsIcon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import LarianTechCloud from "../../../images/LarianTechCloud.png";
import LarianTechGate from "../../../images/LarianTechGate.png";
import LarianTechNode from "../../../images/LarianTechNode.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TagSnapshot from "../../../images/TagSnapshot.png";
import TabletMock from "../../../images/TabletMock.png";
import useAsync from "../../../hooks/useAsync";
import { sendMailAutomation, sendMailEnvironment } from "../../../api/mail";
import useRequired from "../../../hooks/useRequired";
import { Switch } from "@headlessui/react";
import ReCAPTCHA from "react-google-recaptcha";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const features = [
  {
    name: "Equipment List",
    description:
      "Our platform adopts a one-source-of-truth methodology to keep your whole team in the loop",
    icon: TableCellsIcon,
  },
  {
    name: "Incidents",
    description:
      "Our built-in ticketing system keeps track of any issues related to the lab",
    icon: ShieldExclamationIcon,
  },
  {
    name: "Service & Maintenance",
    description:
      "Set reminders for service schedules and keep the rest of your team informed on upcoming visits",
    icon: WrenchScrewdriverIcon,
  },
  {
    name: "Manuals & Documents",
    description:
      "Upload manuals, quotes, POs, and other valueable documents for easy future access",
    icon: DocumentTextIcon,
  },
  {
    name: "Vendor Information",
    description:
      "Our directory keeps track of all your vendors, connects them to equipment, and logs their work",
    icon: UsersIcon,
  },
  {
    name: "Analysis",
    description:
      "Use all the saved data points to create important data-driven decisions about your lab",
    icon: ChartBarIcon,
  },
];

export default () => {
  const { pathname, hash, key } = useLocation();
  const [form, setForm] = useState({});
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          const y =
            element.getBoundingClientRect().top + window.pageYOffset - 250;

          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  /*<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
<script>
  hbspt.forms.create({
    region: "na1",
    portalId: "47473806",
    formId: "97c7f8ae-e98b-4a1b-8b2c-ff616ee9effa"
  });
</script>*/

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "47473806",
          formId: "97c7f8ae-e98b-4a1b-8b2c-ff616ee9effa",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  const {
    value: result,
    execute: onSendMail,
    status: isSendingMail,
    error,
  } = useAsync(sendMailEnvironment, {
    immediate: false,
    showNotification: { error: "raw" },
    skipSuccessNotification: true,
  });

  const hasAll = useRequired(form, [
    "privacy",
    "name",
    "email",
    "message",
    "recaptcha",
  ]);

  return (
    <Content>
      <div className="lg:pb-0">
        <div className="mx-auto max-w-7xl px-6 py-32 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Welcome to the LRIG New England 2024 Fall Seminars and Exhibitions
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Thanks for stopping by our booth! Please fill out the form below
              to stay in touch after the conference
            </p>
            <div className="my-10">
              {" "}
              <div id="hubspotForm"></div>
            </div>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://calendly.com/cezary-scipods"
                target="_blank"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                or schedule a demo
              </a>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};
